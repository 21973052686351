<template>
  <div>
    <location />
    <div class="p08 find_idpwd">
      <div class="wrap-1200">
        <div class="row g-0 align-items-md-stretch">
          <!-- Find ID -->
          <div class="col-lg-6">
            <div class="h-100 border">
              <b-form @submit.prevent="onSubmitFindId">
                <h4>아이디 찾기</h4>
                <p>
                  회원정보에 등록하신 이메일 주소로 아이디를 발송해 드립니다.
                </p>
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    name="email"
                    v-model="form.email"
                    class="form-control form-control-lg"
                    id="floatingInput"
                    placeholder="Enter your email address"
                  />
                  <label for="floatingInput">등록된 이메일 주소</label>
                </div>
                <button type="submit" class="btn btn-primary btn-lg w-100">
                  확인
                </button>
              </b-form>
            </div>
          </div>
          <!-- // Find ID -->
          <!-- Find PWD -->
          <div class="col-lg-6">
            <div class="h-100 border">
              <b-form @submit.prevent="onSubmitFindPw">
                <h4>비밀번호 찾기</h4>
                <p>
                  회원정보에 등록하신 이메일 주소로 임시 비밀번호를 발송해
                  드립니다.
                </p>
                <div class="form-floating mb-3">
                  <input
                    type="id"
                    name="id"
                    v-model="form.id"
                    class="form-control form-control-lg"
                    id="floatingInput"
                    placeholder="Enter your ID"
                  />
                  <label for="floatingInput">아이디</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    name="email"
                    v-model="form.email"
                    class="form-control form-control-lg"
                    id="floatingInput"
                    placeholder="Enter your email address"
                  />
                  <label for="floatingInput">등록된 이메일 주소</label>
                </div>
                <button type="submit" class="btn btn-primary btn-lg w-100">
                  확인
                </button>
              </b-form>
            </div>
          </div>
          <!-- // Find PWD -->
        </div>

        <p class="d-inline-block icon_alarm text-lightgray mt-4">
          등록하신 이메일 주소가 기억나지 않는 경우
          <span class="text-black">닉네임, 전화번호</span>를 입력하여
          <a href="mailto:info@thegolf.com">info@thegolf.com</a>로 이메일을
          보내주십시오.
        </p>
        
      </div>
    </div>
  </div>
</template>

<script>
import ax from "@/api/auth";
import _ from "lodash";
import location from "@/components/Location";

export default {
  name: "Find_Id_Pwd",
  components: {
    location,
  },
  data() {
    return {
      form: {
        email: "",
        id: "",
      },
    };
  },
  methods: {
    onSubmitFindPw() {
      if (!this.form.email) {
        alert("Please enter your email.");
        return false;
      }

      if (!this.form.id) {
        alert("Please enter your Id.");
        return false;
      }
      ax.forgotPw(this.form, (flag, msg) => {
        if (flag) {
          alert(msg);
          this.$router.push("/login");
        } else {
          alert(msg);
        }
      });
    },
    onSubmitFindId() {
      if (!this.form.email) {
        alert("Please enter your email.");
        return false;
      }

      ax.forgotId(this.form, (flag, msg) => {
        if (flag) {
          alert(msg);
          // this.$router.push("/login");
        } else {
          alert(msg);
        }
      });
    },
  },
};
</script>
