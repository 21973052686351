<template>
  <div>
    <location />

    <div class="p08">
      <div class="wrap">
        <b-form name="login_frm" @submit="onSubmit">
          <!-- label class="mb-2 d-block text-dgray">ID<label -->
          <input
            type="text"
            class="form-control form-control-lg mb-4"
            placeholder="아이디"
            aria-label=""
            v-model="form.id"
            name="id"
            autocomplete="off"
          />
          <!--label class="mb-2 d-block text-dgray">Password<label -->
          <input
            type="password"
            class="form-control form-control-lg mb-3"
            placeholder="비밀번호"
            aria-label=""
            v-model="form.pw"
            name="pw"
            autocomplete="off"
          />
          <div class="row g-0 mb-4">
            <div class="col-lg-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="y"
                  name="remember_yn"
                  id="flexCheck"
                  v-model="form.remember_yn"
                  true-value="y"
                  false-value="n"
                  :checked="form.remember_yn === 'y'"
                />
                <label class="form-check-label" for="flexCheck"
                  >아이디 저장</label
                >
              </div>
            </div>
            <div class="col-lg-6 align-right">
              <router-link to="/Find_Id_Pwd" class="d-inline-block"
                >아이디/비밀번호 찾기</router-link
              >
            </div>
          </div>
          <input class="btn btn-primary btn-lg w-100" type="submit" value="로그인" />
          <!-- Social Login UI -->
          <hr>
          <GoogleLogin
            class="btn-outline-secondary btn-lg mb-4 position-relative w-100"
            :params="googleParams"
            :onSuccess="googleSuccess"
            :onFailure="onFailure"
            type="button"
          >
            <span class="icon_sprite_social icon_google"></span>
            구글 로그인
          </GoogleLogin>

          <!-- <button
            type="button"
            class="btn-outline-secondary btn-lg mb-4 position-relative w-100"
            id="facebook"
            @click="facebookLogin()"
          >
            <span class="icon_sprite_social icon_facebook"></span>
            페이스북 로그인
          </button> -->

          <button
            type="button"
            class="btn-outline-secondary btn-lg mb-4 position-relative login-button w-100"
            @click="vueAppleLogin"
          >
            <span class="icon_sprite_social icon_apple"></span>애플 로그인
          </button>

          <vue-apple-login
            style="display: none"
            :onSuccess="appleSuccess"
            :onFailure="onFailure"
            ref="apple"
          ></vue-apple-login>
          <div>
            <p class="d-inline-block icon_alarm">
              X-GOLF 회원이 아니신가요? &nbsp;
            </p>
            <router-link :to="{ name: 'SignUp' }" class="d-inline-block">
              회원가입
            </router-link>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import ax from "@/api/auth";
import _ from "lodash";
import location from "@/components/Location";
import facebook from "@/social/facebook.js";
import apple from "@/social/apple.js";
import google from "@/social/google.js";
import GoogleLogin from "vue-google-login";
// import { loadScript } from "vue-plugin-load-script";

export default {
  name: "Login",
  data() {
    return {
      form: {
        id: localStorage.getItem("remember_id") || "",
        pw: "",
        remember_yn: localStorage.getItem("remember_yn") || "",
      },
      loginError: false,
      googleParams: { client_id: process.env.VUE_APP_SOCIAL_GOOGLE_CLIENT_ID },
    };
  },
  components: {
    location,
    GoogleLogin,
  },
  created() {
    if (this.$store.state.user !== null) {
      // this.$router.push('/dashboard')
    }
    this.form = _.cloneDeep(this.form);

    // loadScript(
    //   `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`
    // ).then(() => {
    //   // if (!window.APPLE) apple.init();
    // });
    if (!window.FB) facebook.init();
  },

  methods: {
    vueAppleLogin() {
      this.$refs.apple.$el.click();
    },
    onSubmit(event) {
      event.preventDefault();
      if (this.form.id === "") {
        alert("Please enter your ID.");
        return false;
      }

      if (this.form.pw === "") {
        alert("Please enter a password.");
        return false;
      }

      ax.getToken(this.form, (flag, data) => {
        if (flag) {
          this.$router.push("/membership/mysummary");
        } else {
          this.resetForm();
          alert(data.message);
          if (data.code === 301) {
            if (confirm("등록된 이메일로 인증메일을 받으시겠습니까?")) {
              ax.verifyEmail(this.form, (flag, code, message) => {
                if (flag) {
                  if (code === 200) {
                    alert(message);
                    // this.$router.push('/login')
                    this.$router.push({ name: "Login" });
                    return true;
                  } else {
                    // alert(code)
                    //alert('code가 200이 아님')
                    return false;
                  }
                } else {
                  alert(message);
                  return false;
                }
              });
            }
          }
        }
      });
    },
    resetForm() {
      this.form = _.cloneDeep(this.form);
    },
    facebookLogin() {
      if (sessionStorage.getItem("token")) {
        alert("이미 로그인 되어 있습니다.");
        this.$router.push("/membership/mysummary");
      } else {
        facebook.login();
      }
    },
    async googleSuccess(googleUser) {
      if (sessionStorage.getItem("token")) {
        alert("이미 로그인 되어 있습니다.");
        this.$router.push("/membership/mysummary");
      } else {
        google.login(googleUser);
      }
    },
    async appleSuccess(appleUser) {
      console.log(appleUser);
      if (sessionStorage.getItem("token")) {
        alert("이미 로그인 되어 있습니다.");
        this.$router.push("/membership/mysummary");
      } else {
        apple.login(appleUser);
      }
    },
    onFailure(error) {
      console.log(error);
    },
  },
};
</script>
